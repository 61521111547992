$primary: #904BD6;

$purple: #904BD6;
$blue: #4A8DD3;
$green: #41DC7B;
$yellow: #FFE04C;
$pink: #E243AA;
$red: #FC4B58;

$grey-50: #EAEDF3;
$grey-100: #CBD4DC;
$grey-200: #ABB7C3;
$grey-300: #8B9AAB;
$grey-400: #738598;
$grey-500: #5B7186;
$grey-600: #4F6375;
$grey-700: #3F505F;
$grey-800: #313D4A;
$grey-900: #1F2933;