@import "../../../styles.scss";

.rs-input {
  background-color: $grey-800 !important;
  border: none !important;
  border-radius: 4px !important;
  color: $grey-50 !important;

  &:focus {
    background-color: $grey-700 !important;
  }

  &::placeholder {
    opacity: 0.5 !important;
    color: $grey-50 !important;
  }
}
