@import "../../../styles.scss";

.header {
  background-color: $grey-900;
  color: $grey-200;
  border-bottom: $grey-800 solid 1px;
  font-size: 1.3rem;
  height: 3rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;  
}

.nav_links {
  a {
    margin-left: 1.2rem;
    padding: 0.5rem 1rem;
    text-decoration: none !important;
    color: $grey-200;
    border-radius: 4px;

    &:hover {
      background-color: $grey-800;
      text-decoration: none;
      color: $grey-50 !important;
    }

    &.active {
      color: $grey-50 !important;
    }
  }
}

.custom_input {
  background-color: $grey-800;
  border: none;
  border-radius: 4px;
  color: $grey-50;

  &:focus {
    background-color: $grey-700;
  }

  &::placeholder {
    opacity: .5;
    color: $grey-50;
}
}


.header_search_wrapper {
  width: 300px;
  max-width: 300px;
}
